<template>
    <div>
         <Top />
        <div class="paragraph">
            <p v-html="info"></p>

        </div>
        <Footer />
    </div>
</template>

<script>
import Footer from "@/components/Footer.vue"
import Top from "@/components/Top.vue"

export default {
    data(){
        return{

        }
    },
    components:{
        Footer,Top
    },
    async  created() {
        await this.$store.dispatch("serverStart")
        await this.$store.dispatch("getInfo","datenschutz");
    },
    computed:{
        info(){
            return this.$store.getters.getInfo[0].info
        }
    }
    
}
</script>

<style scoped>
    .paragraph{
        margin-top: 100px;
        min-height: 70vh;
        width: 70%;
        margin-left: auto;
        margin-right: auto;
        text-align: left;
    }
</style>