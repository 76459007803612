<template>
    <div class="gameBox">
        <div class="quizBoxWrapper">
        <div class="arrowLeft" @click="goBack()"><img :src="pfeilWeiss" alt=""></div>    
        <div class="quizBox"  v-if="aufgaben&&nichtgeloest" :key="activeIndex" > 
            
            <div style="font-weight:bold">
                    Frage {{activeIndex+1}} von {{aufgabenMenge}} <br> <br>
            </div>
            <div>
                {{
                    aufgaben[activeIndex].Frage
                }}
            </div>
            <div class="antworten" :key="activeIndex">
                <ul >
                    <li v-for="eingabe,index in aufgaben[activeIndex].eingabe" :key="index">
                        <input type="radio" name="eingabe" id="" @click="checkAnswer(index)"> {{eingabe.antwort}} - <span> {{eingabe.buchstabe}}</span>
                    </li>
                </ul>
            </div>
            <p>Lösung</p>   
            <div  v-if="aufgaben" :key="switchQuestion" class="solutionWrapper">
                
                <span v-for="loesung,index in aufgaben" :key="index" class="solution" :class="index==activeIndex?'active':'notActive'">{{solutions[index]}} </span>
            </div>



        </div>
        <div v-else class="solutionBox">
            <p class="headline2">Sie haben alle Fragen richtig beantwortet</p>
            


            {{answer}}

            <span v-if="showInput">
                <input type="checkbox" name="" id="" v-model="checkAge"  >Ich bin über 18 und mit den <span class="teilnahmeBedingung" @click="openTeilnahme()">Teilnahmebedingungen</span>  einverstanden.
            </span> 
            <div v-if="showInput" class="solutionInnerBox">
                <input type="email" name="email" id="" v-model="message.email" placeholder="Email">
                <input type="text" name="vorname" id="" v-model="message.vorname" placeholder="Vorname">
                <input type="text" name="nachname" id="" v-model="message.nachname" placeholder="Nachname">
                <button @click="sendInfos()">Absenden</button>  

            </div>
              
        </div>
        <div class="arrowRight" @click="goNext()"><img :src="pfeilWeiss" alt=""></div>    

        </div>
        <div class="teilnahmeBedingungBox" v-if="showTeilnahmebedingung" >
            <div v-html="teilnahmebedingung"></div>
            <div class="closeButton" @click="closeModal()">
                <img :src="closeButton" alt="" />
            </div>
        </div>

    
    </div>

</template>

<script>
import gsap from "gsap"
import closeButton from "@/assets/images/icons/kreuz.png";
import pfeilWeiss from "@/assets/images/icons/pfeil_weiss.svg";

export default {
    data(){
        return{
            pfeilWeiss,
            checkAge:false,
            nichtgeloest:true,
            switchQuestion:true,
            showInput:true,
            activeIndex:0,
            solvedQuestions:[],
            solutions:["","","","","","","","","","","","",],
            checked_solutions:[],
            unsolved_solutions:[],
            message:{
                email:null,
                vorname:null,
                nachname:null
            },
            answer:"",
            teilnahmebedingung:null,closeButton,
            showTeilnahmebedingung:false
        }
    },
    methods:{
        checkdate(){
            const test=[
                {
                    "id": 117,
                    "status": "draft",
                    "sort": null,
                    "user_created": "5907d199-ede6-4b86-bbd6-b9e1d5bba734",
                    "date_created": "2022-05-08T14:46:22.000Z",
                    "user_updated": null,
                    "date_updated": null,
                    "email": "josefin.we@gmx.de",
                    "vorname": "Josefin",
                    "nachname": "Wenzel"
                }
            ]

            var result=test.filter( el => {
                
                const userDate=new Date(el.date_created);
                var userMonth = userDate.getMonth();
                userDate.setDate(userDate.getDate() - 9);
                userMonth = userDate.getMonth() ;
                console.log("userDate",userMonth)
                var currentdate = new Date();

                console.log(currentdate)
                var cur_month = currentdate.getMonth();
                console.log("cur_month",cur_month)
                console.log("cur_month",cur_month==userMonth)
                if(cur_month==userMonth)return true
                return false
            })
            


            

            console.log("result",result)
            console.log("result",result)

    
            



        },
        async checkAnswer(index){
            // richtige Antwort
            if(this.aufgaben[this.activeIndex].eingabe[index].richtig){
                
                //show corresponding letter beneath
                let currentIndex=this.activeIndex
                this.solutions[currentIndex]= this.aufgaben[this.activeIndex].eingabe[index].buchstabe+"    " 

                let tl=gsap.timeline()

                await tl.to(".active",{
                    transform:"rotate(-20deg)",
                    duration:.2
                })
                await tl.to(".active",{
                    transform:"rotate(20deg)",
                    duration:.2
                })
                await tl.to(".active",{
                    transform:"rotate(-20deg)",
                    duration:.2
                })
                await tl.to(".active",{
                    transform:"rotate(20deg)",
                    duration:.2
                })
                await tl.to(".active",{
                    transform:"rotate(0deg)",
                    duration:.2
                })
                await gsap.set(".active",{
                    borderBottom:"2px solid white",
                })
               
                // 
                this.checked_solutions.push(this.unsolved_solutions.shift()) 
                if(this.unsolved_solutions.length!=0){
                    this.activeIndex=this.unsolved_solutions[0]
                }else{
                     this.solutions[currentIndex]= this.aufgaben[currentIndex].eingabe[index].buchstabe+"    " 
                    /*setTimeout(function(){
                        let timeline=gsap.timeline({repeat: 6})
                        timeline.to(".solution",{ duration:.1, y:6, ease: "sine", stagger:0.05})
                        timeline.to(".solution",{ duration:.1, y:-6, ease: "sine", stagger:0.05})
                    },1.8)*/
                    let timeline=gsap.timeline({repeat: 3})
                    timeline.to(".solution",{ duration:.1, y:6, ease: "sine", stagger:0.05})
                    timeline.to(".solution",{ duration:.1, y:-6, ease: "sine", stagger:0.05})
                    timeline.pause()
                    await timeline.resume()
                    this.switchQuestion=!this.switchQuestion
                    this.nichtgeloest=false
                    
                }
                
               

            }// falsche Antwort
            else{
                await gsap.to(".active",{
                    borderBottom:"6px solid red",
                    duration:.5
                })
                this.unsolved_solutions.push(this.unsolved_solutions.shift())
                this.activeIndex=this.unsolved_solutions[0]

            }
        },
        goNext(){
            this.unsolved_solutions.push(this.unsolved_solutions.shift())
            this.activeIndex=this.unsolved_solutions[0]


            /* if( this.activeIndex != this.unsolved_solutions.length -1){
                this.activeIndex +=1
            }else{
                this.activeIndex= 0
            } */  
        },
        goBack(){
            this.unsolved_solutions.unshift(this.unsolved_solutions.pop())
            this.activeIndex=this.unsolved_solutions[0]

            /*if( this.activeIndex != 0){
                this.activeIndex -=1
            }else{
                this.activeIndex= this.unsolved_solutions.length -1
            }            */
        },
        async sendInfos(){
            if(this.checkAge){
                if(!(this.message.email==null || this.message.nachname==null || this.message.vorname==null)){
                    let res=await this.$store.dispatch("sendInfos",this.message)
                    this.answer=res.message
                    this.showInput=res.showInput
                }else{
                    alert("Bitte alle Felder ausfüllen")
                }     
            }else{
                alert("Bitte Alter und Teilnamebedingungen bestätigen")
            }
            
        },
        openTeilnahme(){
            this.showTeilnahmebedingung=true
        },
        closeModal() {
            this.showTeilnahmebedingung=false
        },
    },
    computed:{
        aufgaben(){
            return this.$store.getters.getAufgabe
        },
        aufgabenMenge(){
            return this.$store.getters.getAufgabe.length
        }
    },
    async mounted(){
        await this.$store.dispatch("serverStart")
        await this.$store.dispatch("getInfo","teilnahmebedingungen")
        await this.$store.dispatch("getAufgabe")
    

        this.unsolved_solutions=Array.from(this.aufgaben.keys())
        this.activeIndex=this.unsolved_solutions[0]
        let res=this.$store.getters.getInfo
        this.teilnahmebedingung=res[0].info
    }
}
</script>

<style lang="scss" scoped>

.gameBox{
    background-color: #f1882f;
    color: white;
    padding: 50px 0px;
    font-family: "myriad_pro_cond";
    font-size: 24px;

    .headline{
        font-family: 'garland';
        font-size: 90px;
        color: white;
        @media( max-width:425px) {
            font-size: 70px;
        }
    }

    .headline2{
        font-family: 'garland';
        font-size: 50px;
        color: white;
        @media( max-width:425px) {
            font-size: 40px;
        }
    }
}

.gameIntro{
    width: 30%;
    margin: 30px auto;
    @media( max-width:425px) {
        width: 80%;
    }
}

.solutionBox div{
    display: flex;
    flex-direction: column;
    input{
        width: 20%;
    }
    button{
        min-width: 22%;
        &:hover{
            cursor: pointer;
        }
    }
    align-items: center;
    text-align: left;
}

.teilnahmeBedingung{
    font-weight: bold;
    &:hover{
        cursor: pointer;
    }

}


.solutionWrapper{
    display: flex;
    height: 30px;
}

.solution{
    display: block;
    width: 20px;
    font-size: 28px;
    border-bottom: 2px solid white;
    margin-right: 10px;
    text-align: center;
    @media( max-width:425px) {
        font-size: 20px;
    }
}

.active{
    width: 30px;
    border-bottom: 4px solid white;

}
.quizBox{
    text-align: left;
    width: 30%;
    padding: 40px;
    //margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid white;
    text-align: center;
    @media( max-width:425px) {
        padding: 2px;
        width: 95%;
    }
}

.quizBoxWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    .arrowLeft{
        img{
            width: 30px;
            transform: rotate(90deg);
        }
        &:hover{
            cursor: pointer;
        }
        @media(max-width:425px){
            display: none
        }
    }
    .arrowRight{
        img{
            width: 30px;
            transform: rotate(-90deg);
        }
        &:hover{
            cursor: pointer;
        }
        @media(max-width:425px){
            display: none
        }
    }
}

.antworten{
    ul{
        list-style-type:none;
        padding:0px;
        
    }
    text-align: left;
    @media( max-width:425px) {
        font-size: 18px;
    }
}


input[type="radio"]{
    -webkit-appearance: none;
	background-color: #fafafa;
	border: 1px solid white;
	padding: 9px;
	border-radius: 0px;
	display: inline-block;
	position: relative;
}


input[type="email"], input[type="text"]{
    -webkit-appearance: none;
	background-color: #fafafa;
	border: 1px solid white;
	padding: 9px;
	border-radius: 0px;
	display: inline-block;
	position: relative;
    margin:10px;
    font-family: "myriad_pro_cond";

}

button{
    margin-top: 20px;
    background: white;
    border: white;
    padding: 5px;
    color: #f1882f;
    font-size: 24px;
    font-weight: bold;
    font-family: "myriad_pro_cond";
    box-shadow: 1px 2px 2px 2px black;
}

input[type="radio"]:active, input[type="radio"]:checked:active {
	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
}

input[type="radio"]:checked{
    border-radius: 0;
    &:active{
        background-color: #e9ecee;
        border: 1px solid #adb8c0;
        box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
        color: #99a1a7;
    }

}

input[type="radio"]:checked:after {
	content: 'X';
	font-size: 18px;
    font-weight: bolder;
	position: absolute;
	top: -1px;
    left: 3px;
	color: #f1882f;
}

.teilnahmeBedingungBox{
    position: fixed;
    top: 10px;
    left: 4%;
    width: 82%;
    height: 90vh;
    background-color: white;
    z-index: 2;
    border: 2px solid black;
    color: #000;
    padding: 50px;
    text-align: left;
    overflow-y: scroll;
}

</style>