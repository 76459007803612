<template>
  <div class="home">


    <Top />
    <Intro />
    <video-grid v-on:openDetail="showDetail()"> </video-grid>
    <Bereiche />
    <detail-view ref="detailView" :key="viewChange" v-on:closeDetail="closeView()" class="test"></detail-view>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import VideoGrid from '@/components/VideoGrid.vue'
import DetailView from '@/components/DetailView.vue'
import Top from '@/components/Top.vue'
import Intro from '@/components/Intro.vue'
import Footer from '@/components/Footer.vue'
import Bereiche from '@/components/Bereiche.vue'

export default {
  name: 'Home',
  components: {
    VideoGrid,
    DetailView,
    Top,
    Intro,
    Footer,
    Bereiche
  },
  data () { 
    return {
      video:null,
      viewChange:false
    }
  },
  async mounted(){
    this.$refs.detailView.$el.style.visibility="hidden"
    this.details = this.$store.getters.getCurrentDetail;
    let query=this.$router.currentRoute._value.query
    if(query.video){
      await this.$store.dispatch("serverStart")
      await this.$store.dispatch("getDetails")
      await this.$store.dispatch("setCurrentDetail",parseInt(query.video))
      this.showDetail()
    }else{
      console.log("no")
    }
  },
   methods:{
    showDetail(){
      this.viewChange = !this.viewChange
      this.$refs.detailView.$el.style.visibility="inherit"
    },
    closeView(){
      this.$refs.detailView.$el.style.visibility="hidden"

    }
  },
}
</script>
