<template>
    <div>
        <div class="footerLogo">
            <img :src="logoUnterstuetzung" alt="">
        </div>

        <div class="footerGreen">
                <span class="footerKontakt">Kontakt</span>
                <span>Gartenbauverband Berlin-Brandenburg</span>
                <span> Dorfstr.1 | 14513 Teltow/Ruhlsdorf</span>
                <span> Tel: (03328) 35 17 535 | E-Mail: <a href="mailto:info@gartenbau-bb.de">info@gartenbau-bb.de</a></span>
                <span> <a href="https://www.gartenbau-bb.de" target="__blank">www.gartenbau-bb.de</a>  </span>

                <span class="datenschutzImpressum"> <a href="/datenschutz">DATENSCHUTZ</a>   <a href="/impressum">IMPRESSUM</a> </span>
                <span class="socialMedia">
                    <a href="https://www.facebook.com/gartenbau.bb/" target="__blank"> <img :src="facebook" alt=""></a>
                    <a href="https://www.instagram.com/gartenbauverband/?hl=de" target="__blank"> <img :src="instagram" alt=""></a>
        
                </span>

        </div>
    </div>
</template>

<script>
import logoUnterstuetzung from "@/assets/images/logos/land_brb_unterstuetzung.png"
import facebook from "@/assets/images/icons/facebook.svg"
import instagram from "@/assets/images/icons/instagram.svg"
import youtube from "@/assets/images/icons/youtube.svg"

export default {
    data(){
        return{
            logoUnterstuetzung,
            facebook,
            instagram,
            youtube
        }
    }
}
</script>

<style lang="scss">
$green_gb:#006432;


.footerLogo{
    margin: 40px auto;
    img{
        width: 350px;
    }
    @media( max-width:425px) {
        img{
            width: 100%;
            object-fit: cover;
        }
    }
}

.footerGreen{
    background-color: $green_gb;
    color: white;
    font-family: "myriad_pro_cond";
    font-size: 24px;
    text-align: center;
    display: flex;
    flex-direction: column;

    a{
        color: white;
        text-decoration: none;
    }

    .footerKontakt{
        font-weight: bold;
        padding: 20px;
    }

    .datenschutzImpressum{
        padding: 20px;
        a{
            margin: 0px 10px;
        }
    }
}


.socialMedia{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    img{
        width: 30px;
        margin: 10px;
    }
    

}
</style>