<template>
    <div>
        <div class="bereichWrapper">
             <div class="card orange">
                <img :src="logo_gb_6" alt="">
                <span class="headline_card">Gesunde Lebensmittel</span>
                <span>Pflanzen für Vitamine und Nährstoffe</span>
            </div>
            <div class="card brightGreen">
                <img :src="logo_gb_7" alt="">
                <span class="headline_card">Gutes Stadtklima</span>
                <span> Pflanzen für den Balkon, Parks und Garten</span>
            </div>
            <div class="card violette">
                <img :src="logo_gb_5" alt="">
                <span class="headline_card">Futter für Insekten</span>
                <span> Pflanzen für Nektar und Blütenstaub</span>
            </div>
            <div class="card pink">
                <img :src="logo_gb_4" alt="">
                <span class="headline_card">Biodiversität</span>
                <span> Pflanzen als Nist- und Brutplätze <br> </span>
            </div>


            <div class="card green">
                <img :src="logo_gb_3" alt="">
                <span class="headline_card">Saubere Luft</span>
                <span>Pflanzen für Sauerstoff und Luftfeuchtigkeit</span>
            </div>
            <div class="card brown">
                <img :src="logo_gb_2" alt="">
                <span class="headline_card">Nachhaltige Produktion</span>
                <span>Pflanzen für unsere Zukunft <br>  <br></span>
            </div>
             <div class="card red">
                <img :src="logo_gb_1" alt="">
                <span class="headline_card">Genuss und Freude</span>
                <span> Pflanzen für Schönheit und Wohlbefinden</span>
            </div>
            <div class="card blue">
                <img :src="logo_gb_0" alt="">
                <span class="headline_card">Lebendige Erinnerung</span>
                <span>Pflanzen für die letzte Reise <br>  <br></span>
            </div>
           
            
            
            
            
            
            
            
        </div>
    </div>
</template>

<script>
import logo_gb_0 from "@/assets/images/bereiche/logo_gb_0.png"
import logo_gb_1 from "@/assets/images/bereiche/logo_gb_1.png"
import logo_gb_2 from "@/assets/images/bereiche/logo_gb_2.png"
import logo_gb_3 from "@/assets/images/bereiche/logo_gb_3.png"
import logo_gb_4 from "@/assets/images/bereiche/logo_gb_4.png"
import logo_gb_5 from "@/assets/images/bereiche/logo_gb_5.png"
import logo_gb_6 from "@/assets/images/bereiche/logo_gb_6.png"
import logo_gb_7 from "@/assets/images/bereiche/logo_gb_7.png"


export default {
    data(){
        return{
            logo_gb_0,
            logo_gb_1,
            logo_gb_2,
            logo_gb_3,
            logo_gb_4,
            logo_gb_5,
            logo_gb_6,
            logo_gb_7,
        }
    }
}
</script>
<style lang="scss">
    .bereichWrapper{
        display: flex;
        flex-wrap: wrap;
        max-width: 1024px;
        margin: 60px auto;
        @media( max-width:425px) {
            justify-content: center;
        }
        .card{
            width: 23%;
            margin-right: 2%;
            @media( max-width:425px) {
                width:  50%;
            }
            img{
                width: 60%;
                margin: auto;
                object-fit: cover;
            }
            display: flex;
            flex-direction: column;
            font-family: 'myriad_pro_cond';
            font-size: 24px;
            text-align: center;
            .headline_card{
                font-weight: bold;
            }
            
        }
        .orange{
                color: #F18A35;
        }
        .brightGreen{
            color: #8DBD55;
        }
        .violette{
            color: #685995;
        }
        .pink{
            color: #BB52A8;
        }

        .green{
           color: #4AAA67;
        }

        .brown{
            color: #977042;
        }

        .red{
            color: #DB153B;
        }

        .blue{
            color: #4890BE;
        }
    }
</style>