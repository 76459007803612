<template>
    <Game />
</template>
<script>
import Game from '@/components/GameApp.vue'

export default {
    components:{
        Game
    }
}
</script>

<style lang="scss">

</style>