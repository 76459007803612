<template>
    <div class="introWrapper">
        <div class="logoZukunft">
            <img :src="logo" alt="">
        </div>
        <p class="headline">Pflanzen sind unser Leben</p>
        <div class="intro">
            <p>
                Pflanzen sind unser Leben.
                 Das gilt nicht nur für die Gärtnerinnen und Gärtner der rund 420 Betriebe in Berlin und Brandenburg, 
                 sondern für uns alle. Gärtnerische Produkte sind ein essenzieller Bestandteil unseres Lebens – 
                 für unsere Ernährung und unseren Genuss, für unsere Seele und vor allem für unsere Zukunft.

                <br><br>
                Stellvertretend für die ganze Branche haben wir acht Profis gefragt, vor welchen Herausforderungen sie stehen und welche Lösungen sie finden, 
                damit die Pflanzen, die wir alle brauchen, auch zukünftig wachsen und gedeihen.
            </p>
        </div>
    </div>
</template>

<script>
import logo from "@/assets/images/logos/zukunft_gb.svg"
export default {
    data(){
        return{
            logo
        }
    }
}
</script>

<style lang="scss">
@font-face {
    font-family: 'garland';
    src: url('../assets/fonts/Garland.ttf') format("truetype");
}

@font-face {
    font-family: 'myriad_pro_cond';
    src: url('../assets/fonts/MYRIADPRO-COND.woff');
}
$green_gb:#006432;

.introWrapper{
    background-color: white;
}

.headline{
    font-family: 'garland';
    font-size: 113px;
    color: $green_gb;
    margin: 0;
    @media( max-width:425px) {
        font-size: 70px;
    }
}

.intro{
    font-family: "myriad_pro_cond";
    font-size: 24px;
    width:  40%;
    margin: 30px auto;
     @media( max-width:425px) {
        width:  90%;
    }
}

.logoZukunft{
    img{
        width: 250px;
        height: auto;
        margin: 50px auto 40px auto;
    }
}
</style>