<template>
  <div class="videoBackground" ref="videoBackground">
    <div class="videoBackgroundInner">
      <div class="videoCard" v-if="details" ref="videoCard">
        <div :key="preview" class="videoBoard">
          <video
            :src="videoUrl + details.video"
            controls
            ref="videoplayer2"
            :poster="videoUrl + details.preview_image"
          ></video>
        </div>
        <div class="contentDetail">
          <div class="mainContent">
            <h2>{{ details.titel }}</h2>
            <p v-html="details.paragraph"></p>
          </div>
          <div class="personenContent">
            <p class="zitat">{{ details.zitat }}</p>

            <p class="personenName">{{ details.person }}</p>
            <p class="personenOrt">{{ details.personen_info }}</p>
          </div>
        </div>
        <div class="closeButton" @click="closeModal()">
          <img :src="closeButton" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import closeButton from "@/assets/images/icons/kreuz.png";
import jahreLogo from "@/assets/images/icons/75_jahre_logo.png";
import playButton from "@/assets/images/icons/play_rot.png";
import gsap from "gsap";
export default {
  data() {
    return {
      details: null,
      showPreview: true,
      preview: true,
      closeButton,
      jahreLogo,
      playButton,
      videoplaying: false,
      videoUrl: "https://cms.zukunft-gartenbau.de/assets/",
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeDetail");
      if (this.$refs.videoplayer) {
        this.$refs.videoplayer.pause();
      }
      if (this.$refs.videoplayer2) {
        this.$refs.videoplayer2.pause();
      }
    },
    showPlay() {
      if (!this.videoplaying) {
        gsap.to(".playMe", {
          duration: 1,
          x: 400,
          opacity: 1,
        });
      }
    },
    hidePlay() {
      gsap.to(".playMe", {
        duration: 1,
        x: -400,
        opacity: 0,
      });
    },
    loadVideo() {
      this.showPreview = false;
      this.preview = !this.preview;
      this.videoplaying = true;
      this.hidePlay();
    },
  },
  mounted() {
    this.details = this.$store.getters.getCurrentDetail;

  },
};
</script>

<style lang="scss">
$green_gb: #006432;

.zitat {
  font-family: "garland";
  text-align: center;
  font-size: 60px;
  margin: 0px;
  color: $green_gb;
  line-height: 1;
  margin-bottom: 20px;
}

.personenName {
  font-weight: bold;
  font-family: "myriad_pro_cond";
  font-size: 24px;
  margin: 1px;
  text-align: center;
  color: $green_gb;
}

.personenOrt {
  font-family: "myriad_pro_cond";
  font-size: 24px;
  margin: 1px;
  text-align: center;
  color: $green_gb;
}

.videoBackgroundInner {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: rgba($color: #000000, $alpha: 0.8);
}
.personenParagraph {
  padding-top: 18px;
  font-size: 14px;
}

.playMe {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  clip-path: polygon(0 0, 60% 0, 100% 100%, 0% 100%);
  @media (max-width: 425px) {
    width: 60%;
    clip-path: polygon(0 0, 70% 0, 100% 100%, 0% 100%);
  }
  opacity: 0;
  background-color: rgba($color: $green_gb, $alpha: 0.8);
  margin-left: -400px;
}

.personenContentImage {
  display: flex;
  justify-content: center;
}
.videoBoard {
  position: relative;
  video {
    object-fit: cover;
    width: 90%;
    @media (max-width:425px) {
      &:fullscreen{
        object-fit: contain;
        width: 100%;
      }

    }
  }
}
.previewVideo {
  &:hover {
    cursor: pointer;
  }
}
.playVideoButton {
  position: absolute;
  bottom: 50px;
  left: 50px;
  display: flex;
  &:hover {
    cursor: pointer;
  }
  img {
    width: 150px;
    @media (max-width: 425px) {
      width: 20px;
      height: auto;
    }
  }
  .playVideoButtonText {
    color: white;
    margin-top: 50px;

    margin-left: 20px;
    font-size: 30px;
    @media (max-width: 425px) {
      margin-top: 5px;
      font-size: 10px;
    }
  }
}

.videoCard {
  position: fixed;
  width: 90%;
  max-width: 1200px;
  background: white;
  min-height: 200px;
  max-height: 90vh;
  top: 30px;
  overflow-y: auto;
  video {
    width: 99%;
    margin-top: 0.5%;
  }
}
.closeButton {
  position: absolute;
  display: block;
  right: 50px;
  top: 20px;
  font-size: 40px;
  color: white;
  width: 20px;
  height: 20px;
  z-index: 3;
  img {
    width: 50px;
    &:hover {
      cursor: pointer;
    }
  }
}

.contentDetail {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 5%;
  h2 {
    font-size: 18px;
  }
  text-align: left;

  .mainContent {
    font-family: "myriad_pro_cond";
    color: #000;
    font-size: 18px;
    width: 59%;
    @media (max-width: 425px) {
      width: 100%;
    }
  }
  .personenContent {
    width: 35%;
    text-align: left;
    color: #2880b9;
    @media (max-width: 425px) {
      width: 100%;
    }
    img {
      margin-top: 50px;
      width: 65%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

</style>