import { createStore } from 'vuex'
import axios from 'axios'

//const base_url="https://cms.zukunft-gartenbau.de"
const base_url=process.env.VUE_APP_API_URL

export default createStore({
  state: {
    token: null,
    refreshToken: null,
    navigation:null,
    videoCard:null,
    timeline:null,
    details:null,
    currentDetail:null,
    info:null,
    aufgabe:null
  },
  mutations: {
    setToken(state, token) {
      state.token = token
    },
    setAufgabe(state,aufgabe){
      state.aufgabe=aufgabe
    },  



    setRefreshToken(state, token) {
      state.refreshToken = token
    },
    setVideoCards(state,videoCard){
      state.videoCard=videoCard
    },    
    setDetails(state,details){
      state.details=details
    },
    setCurrentDetail(state,id){
      let temp=state.details.filter(el=> el.id == id)
      state.currentDetail=temp[0]
    },
    setInfo(state,info){
      state.info=info
    }
  },
  actions: {
    async serverStart({ commit, dispatch }) {

      return new Promise((resolve, reject) => {
        axios.post(base_url+'/auth/login', {
          email: "schwarz@runze-casper.de",
          password: "admin123",
          mode: "cookie"
        }).then(resp => {
          const token = resp.data.data.access_token
          commit('setToken', resp.data.data.access_token)
          commit('setRefreshToken', resp.data.data.refresh_token)
          resolve(resp)
        }).catch(err => {
        })
      })
    },
    
    async getAufgabe({commit, dispatch},page){
      const res= await axios.get(base_url+'/items/aufgabe?fields=*,eingabe.*', {
        'headers': {
          'Authorization': 'Bearer ' + this.state.token,
        },        
      })
      commit("setAufgabe",res.data.data)
    },

    async getVideos({commit, dispatch}){
      const res= await axios.get(base_url+'/items/video_card', {
        'headers': {
          'Authorization': 'Bearer ' + this.state.token,
        }
      })
      commit("setVideoCards",res.data.data)
    },
    async getDetails({commit, dispatch}){
      const res= await axios.get(base_url+'/items/detail_view', {
        'headers': {
          'Authorization': 'Bearer ' + this.state.token,
        }
      })
      commit("setDetails",res.data.data)
    },
    setCurrentDetail({commit, dispatch},id){
      commit("setCurrentDetail",id)
    },
    async sendInfos({},sendInfos){
      console.log(sendInfos)

      const ques= await axios.get(base_url+'/items/teilnehmer?filter[email][_eq]='+sendInfos.email,{
        headers: { 'Authorization': 'Bearer jqR4Dj6YAy4N7Vmac64s' }
      })
      
      console.log(ques)
      let info=ques.data.data

      var infoResult=null
      if(info){
        
        var infoResult=info.filter( el => {
                
          const userDate=new Date(el.date_created);
          var userMonth = userDate.getMonth();
          userDate.setDate(userDate.getDate() - 9);
          userMonth = userDate.getMonth() ;
          var currentdate = new Date();

          var cur_month = currentdate.getMonth();
          if(cur_month==userMonth)return true
          return false
        })
  
      }
     
      if(ques.request.status==200){
        if(infoResult.length===0){
          const res= await axios.post(base_url+'/items/teilnehmer'
          ,{
            "email":sendInfos.email,
            "vorname": sendInfos.vorname,
            "nachname": sendInfos.nachname
          },{
            headers: { 'Authorization': 'Bearer jqR4Dj6YAy4N7Vmac64s' }
          }
        )
          return {
            message:"Ihre Daten wurden eingereicht",
            showInput:false
          }

        }else{
          return {
            message:"Sie haben diesen Monat schon teilgenommen",
            showInput:false
          }
        }
      }else{
        return {
          message:"Fehler bei der Übermittelung. Bitte prüfen Sie ihre Eingaben und probieren es nochmal.",
          showInput:false
        }

      }

    
      /*const res= await axios.post(base_url+'/items/teilnehmer'
        ,{
          "email":sendInfos.email,
          "vorname": sendInfos.vorname,
          "nachname": sendInfos.nachname
        },{
          headers: { 'Authorization': 'Bearer jqR4Dj6YAy4N7Vmac64s' }
        }
      )*/
    },
    async getInfo({commit, dispatch},page){

      const res=await axios.get(base_url+'/items/info?filter[seite][_eq]='+page, {
        headers: { 'Authorization': 'Bearer jqR4Dj6YAy4N7Vmac64s' }
      })
      commit("setInfo",res.data.data)
    
    },
  },  
  getters: {
    getVideos: state => {
      return state.videoCard
    },

    getAufgabe: state => {
      return state.aufgabe
    },
    getDetails: state => {
      return state.details
    },
    getCurrentDetail: state => {
      return state.currentDetail
    },
    getInfo: state => {
      return state.info
    },
  },
  modules: {
  }
})



