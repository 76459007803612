<template>
    <div class="navigation">
        <div class="navigatioInner">
            <a href="/" class="logo"><img :src="gartenbauverbandLogo" alt=""></a>
                    <div class="burgermenu">
                        <div class="greenline"></div>
                        <div class="greenline"></div>
                        <div class="greenline"></div>

                    </div>

        </div>
        
    </div>
</template>

<script>
import gartenbauverbandLogo from  "@/assets/images/logos/gartenbauverband_b_brb.svg"
import burgermenu from  "@/assets/images/navigation/burger.svg"

export default {
    data(){
        return{
            gartenbauverbandLogo,
            burgermenu
        }
    }
}
</script>

<style lang="scss">
    $green_gb:#006432;

    .navigation{
        display: flex;
        justify-content:center;
        padding: 20px 0px;
        border-bottom: 2px solid $green_gb;
    }

    .navigatioInner{
        display: flex;
        justify-content: start;
        max-width: 1200px;
        width: 90%;
    }
    .logo{
        img{
            width: 150px;
                
        }
    }
    .burgermenu{
        width: 20px;
        height: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: auto;
        margin-bottom: auto;
        display: none;
    }
    .greenline{
        display: block;
        height: 2px;
        background-color: $green_gb;
        width: 100%;
        
    }


</style>